export enum PrismicDocumentType {
  HOME = 'HOME',
  HOME_ENGLISH = 'HOME_ENGLISH',
  PAGE = 'PAGE',
  BLOG_POST = 'BLOG_POST',
  JOB_OFFER = 'JOB_OFFER',
  JOB_OFFER_FORM = 'JOB_OFFER_FORM',
  CONTACT_ENGLISH = 'CONTACT_ENGLISH',
}

export function linkGenerator(
  type: PrismicDocumentType,
  uid?: string | null | undefined,
  parentUid?: string | null | undefined
): string {
  switch (type) {
    case PrismicDocumentType.HOME: {
      return '/'
    }
    case PrismicDocumentType.HOME_ENGLISH: {
      return '/en'
    }
    case PrismicDocumentType.CONTACT_ENGLISH: {
      return '/contact/en'
    }
    case PrismicDocumentType.PAGE: {
      if (uid === 'preview') throw new Error("'preview' uid is reserved.")

      return `/${uid}`
    }
    case PrismicDocumentType.BLOG_POST:
    case PrismicDocumentType.JOB_OFFER: {
      return `/${parentUid}/${uid}`
    }
    case PrismicDocumentType.JOB_OFFER_FORM: {
      return uid ? `/${parentUid}/${uid}/postuler` : `/${parentUid}/postuler`
    }
    default: {
      throw new Error('Unknown type.')
    }
  }
}

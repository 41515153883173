import styled from 'styled-components'
import React, { useState, useEffect, useRef, RefObject } from 'react'

import { BooleanQueryOperatorInput } from '../../gatsby-types'

interface IProps {
  children: any
  fullWidth?: boolean
  fullHeight?: BooleanQueryOperatorInput
}

function FadeInSection(props: IProps) {
  const { children, fullWidth, fullHeight } = props
  const [isVisible, setVisible] = useState(true)
  const domRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer: any = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting))
    })
    if (domRef.current && observer) {
      observer.observe(domRef.current)
    }

    return () => {
      if (domRef.current && observer) {
        observer.unobserve(domRef.current)
      }
    }
  }, [])

  return (
    <StyledFadeInSection
      className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
      fullHeight={fullHeight}
      fullWidth={fullWidth}
      ref={domRef}
    >
      {children}
    </StyledFadeInSection>
  )
}

const StyledFadeInSection = styled.div<{ fullWidth?: boolean; fullHeight?: boolean }>`
  height: ${({ fullHeight }) => (fullHeight ? '100%' : 'unset')};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

  &.fade-in-section {
    opacity: 0;
    transition: opacity 1200ms ease-in-out;
    will-change: opacity;
  }

  &.fade-in-section.is-visible {
    opacity: 1;
  }
`

export default FadeInSection

/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/camelcase */
import { TemplateHomeDataQuery } from '../../../gatsby-types'
import { linkGenerator, PrismicDocumentType } from '../../utils/link-generator'

import { HomeSliceType, IHomeContent, IHomeSlice } from './types'

export function transformHomeNode(node: TemplateHomeDataQuery['prismicHome']): IHomeContent & { slices: IHomeSlice[] } {
  if (!node) throw new Error('Invalid home node.')

  const { data } = node
  if (!data) throw new Error('Invalid home node.')

  const { name, body } = data
  if (!name || !name.text || !body) {
    throw new Error('Invalid home node.')
  }

  return {
    name: name.text,
    slices: body.map((slice) => {
      if (!slice) throw new Error('Invalid home node.')

      // TODO: fix using discriminated unions from "gatsby-types-2.ts"
      const { slice_type, primary, items } = slice

      switch (slice_type) {
        case 'main_title_block': {
          if (
            !primary ||
            !primary.title ||
            !primary.title.html ||
            !primary.subtitle ||
            !primary.subtitle.text ||
            !primary.leftlinklabel ||
            !primary.leftlinklabel.text ||
            !primary.leftlinkurl ||
            !primary.leftlinkurl.url
          ) {
            throw new Error('Invalid home main_title_block slice.')
          }

          return {
            type: HomeSliceType.MAIN_TITLE_BLOCK,
            content: {
              title: primary.title.html,
              subtitle: primary.subtitle.text,
              leftLink: { label: primary.leftlinklabel.text, url: primary.leftlinkurl.url },
              rightLink:
                primary.rightlinklabel &&
                primary.rightlinklabel.text &&
                primary.rightlinkurl &&
                primary.rightlinkurl.url
                  ? { label: primary.rightlinklabel.text, url: primary.rightlinkurl.url }
                  : undefined,
            },
          }
        }
        case 'fullscreen_parallax': {
          return {
            type: HomeSliceType.FULLSCREEN_PARALLAX,
            content: {
              background: {
                url: primary.background_image && primary.background_image.url ? primary.background_image.url : '',
                alt: primary.background_image && primary.background_image.alt ? primary.background_image.alt : '',
              },
              mainText: primary.main_title && primary.main_title.text ? primary.main_title.text : '',
              description: primary.description && primary.description.html ? primary.description.html : '',
            },
          }
        }
        case 'video_iframe': {
          return {
            type: HomeSliceType.VIDEO,
            content: {
              embed: primary.embed_link && primary.embed_link.url ? primary.embed_link.url : '',
            },
          }
        }
        case 'inline_block': {
          if (
            !primary ||
            !primary.title ||
            !primary.title.html ||
            !primary.subtitle ||
            !primary.subtitle.text ||
            !primary.text ||
            !primary.text.html
          ) {
            throw new Error('Invalid home inline_block slice.')
          }

          return {
            type: HomeSliceType.INLINE_BLOCK,
            content: {
              title: primary.title.html,
              subtitle: primary.subtitle.text,
              text: primary.text.html,
              link:
                primary.linklabel &&
                primary.linklabel.text &&
                primary.linkurl &&
                primary.linkurl.document &&
                primary.linkurl.document.uid &&
                primary.linkurl.document.data &&
                primary.linkurl.document.data.parentdocument &&
                primary.linkurl.document.data.parentdocument.uid
                  ? {
                      label: primary.linklabel.text,
                      url: linkGenerator(
                        PrismicDocumentType.BLOG_POST,
                        primary.linkurl.document.uid,
                        primary.linkurl.document.data.parentdocument.uid
                      ),
                    }
                  : primary.linklabel && primary.linklabel.text && primary.linkurl && primary.linkurl.url
                  ? {
                      label: primary.linklabel.text,
                      url: primary.linkurl.url,
                    }
                  : undefined,
            },
          }
        }
        case 'banner': {
          if (!primary || !primary.video || !primary.video.url) {
            throw new Error('Invalid home inline_block slice.')
          }

          return {
            type: HomeSliceType.BANNER,
            content: { videoUrl: primary.video.url },
          }
        }
        case 'edtech_block': {
          if (
            !primary ||
            !primary.lefttitle ||
            !primary.lefttitle.text ||
            !primary.lefttext ||
            !primary.lefttext.html ||
            !primary.righttitle ||
            !primary.righttitle.text ||
            !primary.righttext ||
            !primary.righttext.html
          ) {
            throw new Error('Invalid home edtech_block slice.')
          }

          return {
            type: HomeSliceType.EDTECH_BLOCK,
            content: {
              left: {
                title: primary.lefttitle.text,
                text: primary.lefttext.html,
              },
              right: {
                title: primary.righttitle.text,
                text: primary.righttext.html,
              },
            },
          }
        }
        case 'project_cards': {
          if (
            !primary ||
            !primary.lefttitle ||
            !primary.lefttitle.text ||
            !primary.subtitle ||
            !primary.subtitle.text ||
            !primary.lefttext ||
            !primary.lefttext.html ||
            !primary.righttext ||
            !primary.righttext.html ||
            !primary.linklabel ||
            !primary.linklabel.text ||
            !primary.linkurl ||
            !primary.linkurl.url ||
            !items
          ) {
            throw new Error('Invalid home project_cards slice.')
          }

          return {
            type: HomeSliceType.PROJECT_CARDS,
            content: {
              left: {
                title: primary.lefttitle.text,
                subtitle: primary.subtitle.text,
                text: primary.lefttext.html,
              },
              right: {
                text: primary.righttext.html,
                link: { label: primary.linklabel.text, url: primary.linkurl.url },
              },
              cards: items.map((item) => {
                if (
                  !item ||
                  !item.cardtitle ||
                  !item.cardtitle.text ||
                  !item.cardsubtitle ||
                  !item.cardsubtitle.text ||
                  !item.cardimage ||
                  !item.cardimage.url ||
                  !item.cardlink ||
                  !item.cardlink.document ||
                  !item.cardlink.document.uid ||
                  !item.cardlink.document.data ||
                  !item.cardlink.document.data.parentdocument ||
                  !item.cardlink.document.data.parentdocument.uid
                ) {
                  throw new Error('Invalid home project_cards slice.')
                }

                return {
                  title: item.cardtitle.text,
                  subtitle: item.cardsubtitle.text,
                  background: { alt: item.cardimage.alt || '', url: item.cardimage.url },
                  linkUrl: linkGenerator(
                    PrismicDocumentType.BLOG_POST,
                    item.cardlink.document.uid,
                    item.cardlink.document.data.parentdocument.uid
                  ),
                }
              }),
            },
          }
        }
        case 'brands_block': {
          if (!items) {
            throw new Error('Invalid home brands_block slice.')
          }

          return {
            type: HomeSliceType.BRANDS_BLOCK,
            content: {
              brands: items.map((item) => {
                if (
                  !item ||
                  !item.logo ||
                  !item.logo.url ||
                  !item.title ||
                  !item.title.html ||
                  !item.subtitle ||
                  !item.subtitle.text ||
                  !item.linkurl ||
                  !item.linkurl.url
                ) {
                  throw new Error('Invalid home brands_block slice.')
                }

                return {
                  logo: { alt: item.logo.alt || '', url: item.logo.url },
                  title: item.title.html,
                  subtitle: item.subtitle.text,
                  background: item.image ? { alt: item.image.alt || '', url: item.image.url } : undefined,
                  linkUrl: item.linkurl.url,
                }
              }),
            },
          }
        }
        default: {
          throw new Error('Invalid home node.')
        }
      }
    }),
  }
}

import { TemplateAboutDataQuery } from '../../gatsby-types'

export interface ISEOContent {
  title?: string
  description?: string
  ogTitle?: string
  ogDescription?: string
  ogImage?: string
  ogUrl?: string
  ogSiteName?: string
  twitterCard?: string
  twitterTitle?: string
  twitterDescription?: string
  twitterImage?: string
  canonicalLink?: string
}

type SeoNodeDataQuery = TemplateAboutDataQuery['prismicAbout']['data']['seo']

export function transformSeo(data: SeoNodeDataQuery) {
  if (!data || !data.primary) throw new Error('Invalid seo content.')

  const {
    metatitle,
    metadescription,
    metaogtitle,
    metaogdescription,
    metaogimage,
    metaogurl,
    metaogsitename,
    metatwittercard,
    metatwittertitle,
    metatwitterdescription,
    metatwitterimage,
    canonicallink,
  } = data.primary

  return {
    title: metatitle?.text || undefined,
    description: metadescription?.text || undefined,
    ogTitle: metaogtitle?.text || undefined,
    ogDescription: metaogdescription?.text || undefined,
    ogImage: metaogimage?.url || undefined,
    ogUrl: metaogurl?.text || undefined,
    ogSiteName: metaogsitename?.text || undefined,
    twitterCard: metatwittercard?.text || undefined,
    twitterTitle: metatwittertitle?.text || undefined,
    twitterDescription: metatwitterdescription?.text || undefined,
    twitterImage: metatwitterimage?.url || undefined,
    canonicalLink: canonicallink?.text || undefined,
  }
}

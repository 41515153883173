import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { IBlogPostInformation } from '../../models/blog-post/types'
import FadeInSection from '../fade-in'
import { linkGenerator, PrismicDocumentType } from '../../utils/link-generator'
import Button from '../buttons/main'
import SvgArrowRight from '../svg/arrow-right'

interface IProps {
  data: IBlogPostInformation[]
}

export default function NewsList({ data }: IProps) {
  return (
    <FadeInSection>
      <Container>
        <div>
          <h6>Nos actualités</h6>
          <h5>En ce moment</h5>
          {data &&
            data.map((post, index) => (
              <BlogPostLink key={index} to={linkGenerator(PrismicDocumentType.BLOG_POST, post.uid, post.parent.uid)}>
                <div>
                  <ImageContainer background={post.thumbnailImage.url} />
                  <div>
                    <p>
                      <span>{post.date}</span>
                      <span>•</span>
                      <span>{post.categories[0]}</span>
                    </p>
                    <h6>{post.title}</h6>
                  </div>
                </div>
                <div>
                  <SvgArrowRight />
                </div>
              </BlogPostLink>
            ))}
        </div>
        <Button color="secondary" variant="contained" width="196px">
          <Link to="/actualites">Voir tout</Link>
        </Button>
      </Container>
    </FadeInSection>
  )
}

const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 80px 0;
  width: 100vw;

  @media screen and (max-width: 480px) {
    padding: 32px 16px;
  }

  & > div {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    width: 1280px;

    @media screen and (max-width: 1280px) {
      width: 90%;
    }

    & > a {
      background-color: ${({ theme }) => theme.palette.primary.main};
      border-bottom: ${({ theme }) => `solid 1px ${theme.palette.info.main}33`};
      transition: all 200ms ease-in;

      :hover {
        border-bottom: none;
        transform: scale(1.02);
      }
    }

    & > a:nth-last-child(1) {
      border-bottom: none;
    }

    & > h6 {
      color: ${({ theme }) => theme.palette.text.secondary};
      font-size: 14px;
      text-transform: uppercase;
    }

    & > h5 {
      color: ${({ theme }) => theme.palette.text.secondary};
      font-family: 'Proxima Nova Extrabold', sans-serif;
      font-size: 40px;
      margin: 8px 0 16px 0;

      @media screen and (max-width: 480px) {
        font-size: 24px;
      }
    }
  }

  & > button {
    margin-top: 32px;

    @media screen and (max-width: 480px) {
      width: 100%;
    }

    & > a {
      color: ${({ theme }) => theme.palette.text.secondary};
    }
  }
`

const BlogPostLink = styled(Link)`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 128px;
  justify-content: space-between;
  padding: 16px;
  width: 100%;

  @media screen and (max-width: 480px) {
    height: 96px;
    padding: 0;
  }

  & > div {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;

    & > div:nth-child(2) {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      margin-left: 16px;

      @media screen and (max-width: 480px) {
        margin-left: 0;
      }

      & > p {
        color: ${({ theme }) => theme.palette.text.secondary};
        font-size: 14px;
        margin: 4px 0;
        opacity: 0.5;
        text-transform: uppercase;

        & > span:nth-child(2) {
          margin: 0 4px;
        }

        @media screen and (max-width: 480px) {
          margin: 0;
        }
      }

      & > h6 {
        color: ${({ theme }) => theme.palette.text.secondary};
        font-family: 'Proxima Nova ExtraBold', sans-serif;
        font-size: 24px;
        margin: 4px 0;

        @media screen and (max-width: 480px) {
          font-size: 16px;
          margin: 0;
        }
      }
    }

    & > svg {
      height: 24px;
      opacity: 0;
      width: 24px;

      & > path {
        fill: ${({ theme }) => theme.palette.secondary.main};
      }
    }
  }

  :hover {
    border-radius: 4px;
    box-shadow: 0 6px 24px 0 rgba(0, 29, 47, 0.5);

    & > div > svg {
      opacity: 1;
    }
  }
`

const ImageContainer = styled.div<{ background: string }>`
  background: ${({ background }) => `url("${background}") center center`};
  background-size: cover;
  border-radius: 4px;
  height: 80px;
  width: 112px;

  @media screen and (max-width: 480px) {
    display: none;
  }
`

import React from 'react'
import { graphql } from 'gatsby'

import { TemplateHomeDataQuery } from '../../../gatsby-types'
import Layout from '../../layouts/default'
import Body from '../../components/home/body'
import { transformAllBlogPostNode } from '../blog-post/transformer'
import NewsList from '../../components/home/news-list'
import { transformSeo } from '../../utils/seo-transformer'

import { transformHomeNode } from './transformer'

interface IProps {
  data: TemplateHomeDataQuery
}

export default function Home({
  data: {
    prismicHome,
    allPrismicBlogPost: { nodes },
  },
}: IProps) {
  const contentPage = transformHomeNode(prismicHome)
  const newsPost = transformAllBlogPostNode(nodes)
  const seoContent =
    prismicHome && prismicHome.data && prismicHome.data.seo && prismicHome.data.seo[0]
      ? transformSeo(prismicHome.data.seo[0])
      : undefined

  const { slices } = contentPage

  const parsedUrl = typeof window !== 'undefined' ? window.location.href.split('?gclid=') : []
  const gclid = parsedUrl.length === 2 ? parsedUrl[1] : ''
  if (typeof localStorage !== 'undefined' && gclid.length > 0) localStorage.setItem('gclid', gclid)

  return (
    <Layout seoContent={seoContent} title="Accueil">
      <Body slices={slices} />
      <NewsList data={newsPost} />
    </Layout>
  )
}

export const query = graphql`
  query TemplateHomeData {
    prismicHome {
      data {
        name {
          text
        }
        body {
          ... on PrismicHomeBodyFullscreenParallax {
            slice_type
            primary {
              background_image {
                alt
                url
              }
              description {
                html
              }
              main_title {
                text
              }
            }
          }
          ... on PrismicHomeBodyMainTitleBlock {
            slice_type
            primary {
              title {
                html
              }
              subtitle {
                text
              }
              leftlinklabel {
                text
              }
              leftlinkurl {
                url
              }
              rightlinklabel {
                text
              }
              rightlinkurl {
                url
              }
            }
          }
          ... on PrismicHomeBodyInlineBlock {
            slice_type
            primary {
              title {
                html
              }
              subtitle {
                text
              }
              text {
                html
              }
              linklabel {
                text
              }
              linkurl {
                url
                document {
                  ... on PrismicBlogPost {
                    uid
                    data {
                      parentdocument {
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomeBodyBanner {
            slice_type
            primary {
              video {
                url
              }
            }
          }
          ... on PrismicHomeBodyVideoIframe {
            slice_type
            primary {
              embed_link {
                url
              }
            }
          }
          ... on PrismicHomeBodyEdtechBlock {
            id
            slice_type
            primary {
              lefttitle {
                text
              }
              lefttext {
                html
              }
              righttitle {
                text
              }
              righttext {
                html
              }
            }
          }
          ... on PrismicHomeBodyProjectCards {
            slice_type
            primary {
              lefttitle {
                text
              }
              subtitle {
                text
              }
              lefttext {
                html
              }
              righttext {
                html
              }
              linklabel {
                text
              }
              linkurl {
                url
              }
            }
            items {
              cardtitle {
                text
              }
              cardsubtitle {
                text
              }
              cardimage {
                alt
                url
              }
              cardlink {
                document {
                  ... on PrismicBlogPost {
                    uid
                    data {
                      parentdocument {
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomeBodyBrandsBlock {
            slice_type
            items {
              logo {
                alt
                url
              }
              title {
                html
              }
              subtitle {
                text
              }
              image {
                alt
                url
              }
              linkurl {
                url
              }
            }
          }
        }
        seo {
          ... on PrismicHomeSeoMeta {
            id
            primary {
              metatitle {
                text
              }
              metadecription {
                text
              }
              metaogtype {
                text
              }
              metaogtitle {
                text
              }
              metaogdescription {
                text
              }
              metaogimage {
                url
              }
              metaogurl {
                text
              }
              metaogsitename {
                text
              }
              metatwittercard {
                text
              }
              metatwittertitle {
                text
              }
              metatwitterdescription {
                text
              }
              metatwitterimage {
                url
              }
              canonicallink {
                text
              }
            }
          }
        }
      }
    }
    allPrismicBlogPost(
      sort: { order: DESC, fields: data___postdate }
      filter: { data: { parentdocument: { uid: { ne: "references" } } } }
      limit: 3
    ) {
      nodes {
        uid
        data {
          parentdocument {
            uid
            document {
              ... on PrismicCatalog {
                data {
                  name {
                    text
                  }
                }
              }
              ... on PrismicNews {
                data {
                  name {
                    text
                  }
                }
              }
              ... on PrismicPodcasts {
                data {
                  name {
                    text
                  }
                }
              }
            }
          }
          postcategory
          postsecondarycategories {
            category
          }
          posttemplate
          posttitle {
            text
          }
          postdescription {
            html
          }
          postmainimage {
            alt
            url
          }
          postthumbnailimage {
            alt
            url
          }
          postintroductiontext {
            html
          }
          postdate(locale: "fr", formatString: "DD.MM.YYYY")
        }
      }
    }
  }
`

Home.query = query
